import { ajax } from "rxjs/ajax";
import { of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import env from "config/env";
import processResponse from "./processResponse";

const { API_BASE_URL } = env;

export default function getGuestRequests$({ bookingId }) {
  return ajax.getJSON(`${API_BASE_URL}/reservationItinerary/${bookingId}`).pipe(
    map((response) => response.map(processResponse)),

    catchError((error) => {
      console.log("getGuestRequests ~ error:", error);
      return of([]);
    })
  );
}
