/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import {
  setRequestStateRequesting,
  setRequestStateFulfilled,
  setRequestStateFailed,
  setRequestStateCancelled,
} from "store/apiRequestStates/setRequestState";
import { ALI_PAY_FAILED_ERROR, DATE_CHANGE_ERROR } from "fixtures/constants";
import Cookies from "js-cookies";

export const initialState = {
  alipayPaymentInitialised: undefined,
  alipayError: () => "",
  bookingInProgressId: null,
  bookingInProgressSearchParams: null,
  bookingInProgressPhase: {},
  bookingsInProgress: {},
  byReservationId: {},
  checkoutModifyToken: null,
  requestStates: {},
  cancelRefs: {},
  errors: [],
  supplierErrors: [],
  apiErrors: [],
  deepLinkErrors: {},
  upgradedRooms: [],
  preupgradedBooking: {},
  upsellShown: false,
  invoiceRequestError: "",
  modifyDatesError: "",
  bookingForDateChange: {
    data: {},
    error: "",
  },
  wasBookingInProgress: false,
};

const setBookingIdCookie = (bookingId) => {
  let domain;
  const domainParts = window.location.hostname.split(".");
  if (domainParts.length > 1) {
    domainParts.shift();
    domain = `.${domainParts.join(".")}`;
  }
  if (bookingId) {
    Cookies.setItem("bookingId", bookingId, null, "/", domain);
  } else {
    Cookies.removeItem("bookingId");
    Cookies.removeItem("bookingId", "/", domain);
  }
};

const handleAddPackageSuccess = (
  state,
  { payload: { booking, searchParams } }
) => {
  state.bookingInProgressId = booking.bookingId;
  setBookingIdCookie(booking.bookingId);
  state.bookingInProgressSearchParams = searchParams;
  state.bookingsInProgress[booking.bookingId] = { ...booking, searchParams };
  state.errors = [];
  state.supplierErrors = [];
  state.apiErrors = [];
  state.deepLinkErrors = {};
  state.bookingInProgressPhase = {};
  state.wasBookingInProgress = true;
};

const handleAddPackageFailure = (
  state,
  { payload: { supplierErrors = [], apiErrors = [], errors = [] } }
) => {
  state.errors = errors;
  state.supplierErrors = supplierErrors;
  state.apiErrors = apiErrors;
};

const handleDeepLinkFailure = (
  state,
  { payload: { supplierErrors = [], apiErrors = [] } }
) => {
  state.deepLinkErrors = {
    supplierErrors,
    apiErrors,
  };
};

const bookingsSlice = createSlice({
  name: "bookings",
  initialState,
  reducers: {
    changeRoomEvt() {},
    viewPackagesEvt() {},
    viewNextRoomEvt() {},
    viewPackageIncludedEvt() {},

    addPackage() {},
    addPackageFulfilled: handleAddPackageSuccess,
    addPackageFailed: handleAddPackageFailure,
    addPackageCancel() {},

    singleRoomUpsellEvt(state) {
      state.upsellShown = "singleroom";
    },
    multiRoomUpsellEvt(state) {
      state.upsellShown = "multiroom";
    },
    upgradeRooms() {},
    upgradeRoomsFulfilled(
      state,
      { payload: { roomIndices, booking, prevBooking } }
    ) {
      state.upgradedRooms = [...state.upgradedRooms, ...roomIndices];
      state.preupgradedBooking = prevBooking;
      state.bookingInProgressId = booking.bookingId;
      setBookingIdCookie(booking.bookingId);
      state.bookingsInProgress[booking.bookingId] = booking;
    },
    upgradeRoomsFailed: handleAddPackageFailure,
    upgradeRoomsCancel() {},
    upgradeRoomsClear(state) {
      state.upsellShown = false;
      state.upgradedRooms = [];
      state.preupgradedBooking = {};
    },

    completeBooking() {},
    completeBookingFailed(
      state,
      {
        payload: {
          supplierErrors = [],
          apiErrors = [],
          errors = [],
          bookingInProgressPhase,
        },
      }
    ) {
      state.bookingInProgressPhase = bookingInProgressPhase;
      state.errors = errors;
      state.supplierErrors = supplierErrors;
      state.apiErrors = apiErrors;
    },
    completeBookingFulfilled(state, { payload: { booking } }) {
      const { reservationId } = booking.hotelProducts[0];
      state.bookingsInProgress[booking.bookingId] = booking;
      state.byReservationId[reservationId] = booking;
      state.checkoutModifyToken = booking.checkoutModifyToken;
      setBookingIdCookie();
    },
    completeBookingCancel() {},

    removeContactCustomerPayment() {},
    removeContactCustomerPaymentFulfilled() {},
    removeContactCustomerPaymentFailed() {},
    removeContactCustomerPaymentCancel() {},

    fetchBookingInProgress() {},
    fetchBookingInProgressFulfilled: (state, { payload: { booking } }) => {
      state.bookingsInProgress[booking.bookingId] = booking;
      const { reservationId } = booking.hotelProducts[0];
      if (reservationId) {
        state.byReservationId[reservationId] = booking;
      }
    },
    fetchBookingInProgressFailed(
      state,
      { payload: { supplierErrors = [], apiErrors = [], errors = [] } }
    ) {
      state.bookingInProgressId = null;
      setBookingIdCookie();
      state.bookingInProgressSearchParams = null;
      state.bookingInProgressPhase = {};
      state.errors = errors;
      state.supplierErrors = supplierErrors;
      state.apiErrors = apiErrors;
      state.wasBookingInProgress = false;
    },
    fetchBookingInProgressCancel() {},

    clearBookingInProgress(state) {
      state.bookingInProgressId = null;
      setBookingIdCookie();
      state.bookingInProgressSearchParams = null;
      state.bookingInProgressPhase = {};
    },

    constructBooking() {},
    constructBookingCancel() {},
    constructBookingFulfilled: handleAddPackageSuccess,
    constructBookingFailed: handleAddPackageFailure,

    constructBookingByBookingId() {},
    constructBookingByBookingIdCancel() {},
    constructBookingByBookingIdFailed: handleDeepLinkFailure,
    constructBookingByBookingIdFulfilled(state) {
      state.deepLinkErrors = {};
    },
    clearDeepLinkErrors(state) {
      state.deepLinkErrors = {};
    },

    constructBookingByResultSet() {},
    constructBookingByResultSetCancel() {},
    constructBookingByResultSetFailed: handleDeepLinkFailure,

    setBookingInProgressId(state, { payload: { bookingId } }) {
      state.bookingInProgressId = bookingId;
      setBookingIdCookie(bookingId);
    },

    clearBookingErrors(state) {
      state.errors = [];
      state.apiErrors = [];
      state.supplierErrors = [];
    },

    resetBookings(state) {
      setBookingIdCookie();
      return {
        ...initialState,
        deepLinkErrors: state.deepLinkErrors,
        bookingInProgress: state.bookingInProgress,
        byReservationId: state.byReservationId,
        wasBookingInProgress: state.wasBookingInProgress,
      };
    },

    cancelBooking(state) {
      state.errors = [];
      state.apiErrors = [];
      state.supplierErrors = [];
    },
    cancelBookingFulfilled(state, { payload: { bookingId, cancelRefs } }) {
      state.cancelRefs[bookingId] = cancelRefs.reduce(
        (acc, cancelRef) => ({
          ...acc,
          [cancelRef.productId]: cancelRef,
        }),
        state.cancelRefs[bookingId] || {}
      );
    },
    cancelBookingFailed(state, { payload }) {
      state.errors = payload.errors || [];
      state.apiErrors = payload.apiErrors || [];
      state.supplierErrors = payload.supplierErrors || [];
    },
    cancelBookingCancel() {},

    submitBookingPreferences() {},
    submitBookingPreferencesFulfilled(state, { payload: { booking } }) {
      const { reservationId } = booking.hotelProducts[0];
      state.byReservationId[reservationId] = booking;
      state.bookingsInProgress[booking.bookingId] = booking;
    },
    submitBookingPreferencesFailed() {},
    submitBookingPreferencesCancel() {},

    fetchHistoricBooking(state, { payload }) {
      const { reservationId } = payload;
      state.requestStates[reservationId] = setRequestStateRequesting(
        state.requestStates[reservationId]
      );
    },
    fetchHistoricBookingAndContent(state, { payload }) {
      const { reservationId } = payload;
      state.requestStates[reservationId] = setRequestStateRequesting(
        state.requestStates[reservationId]
      );
    },
    fetchHistoricBookingCancel(state, { payload }) {
      const { reservationId } = payload;
      state.requestStates[reservationId] = setRequestStateCancelled(
        state.requestStates[reservationId]
      );
    },

    fetchHistoricBookingFailed(state, { payload }) {
      const { reservationId } = payload;
      state.requestStates[reservationId] = setRequestStateFailed(
        state.requestStates[reservationId]
      );
      handleAddPackageFailure(state, { payload });
    },
    fetchHistoricBookingFulfilled(state, { payload: booking }) {
      const { reservationId } = booking.hotelProducts[0];
      state.byReservationId[reservationId] = booking;
      state.requestStates[reservationId] = setRequestStateFulfilled(
        state.requestStates[reservationId]
      );
      state.errors = [];
      state.apiErrors = [];
      state.supplierErrors = [];
    },

    fetchHistoricBookingForRegistration() {},
    fetchHistoricBookingForRegistrationCancel() {},
    fetchHistoricBookingForRegistrationFailed: handleAddPackageFailure,
    fetchHistoricBookingForRegistrationFulfilled(state, { payload: booking }) {
      const { reservationId } = booking.hotelProducts[0];
      state.byReservationId[reservationId] = booking;
      setBookingIdCookie();
    },

    bookingInProgressPhaseSet(state, { payload: { bookingInProgressPhase } }) {
      state.bookingInProgressPhase = bookingInProgressPhase;
    },
    bookingInProgressPhaseClear(state) {
      state.bookingInProgressPhase = initialState.bookingInProgressPhase;
    },

    alipayPaymentInitialisedSet(state) {
      state.alipayPaymentInitialised = new Date().toISOString();
    },
    alipayPaymentInitialisedClear(state) {
      state.alipayPaymentInitialised = initialState.alipayPaymentInitialised;
    },

    alipayPaymentFailed(state, { payload = ALI_PAY_FAILED_ERROR }) {
      state.alipayError = payload;
    },
    alipayPaymentFailedClear(state) {
      state.alipayError = () => "";
    },

    requestInvoice() {},
    requestInvoiceFulfilled(state) {
      state.invoiceRequestError = "";
    },
    requestInvoiceFailed(state, { payload }) {
      state.invoiceRequestError = payload;
    },
    requestInvoiceClear(state) {
      state.invoiceRequestError = "";
    },

    subscribeToAllEmails() {},
    subscribeToAllFulfilled() {},
    subscribeToAllFailed() {},
    subscribeToAllCancel() {},

    addReservation() {},
    addReservationFulfilled() {},
    addReservationFailed() {},
    addReservationCancel() {},
    addReservationClear() {},

    retrieveBooking() {},

    modifyHotelProductCust() {},
    modifyHotelProductCustClear() {},
    modifyHotelProductCustFulfilled() {},
    modifyHotelProductCustFailed(state) {
      state.modifyDatesError = DATE_CHANGE_ERROR;
    },

    getRatesForDateChange() {},
    getRatesForDateChangeFulfilled(state, { payload }) {
      state.bookingForDateChange.data = payload;
    },
    getRatesForDateChangeFailed(state, { payload }) {
      state.bookingForDateChange.error = payload;
    },
    getRatesForDateChangeCancel() {},
    getRatesForDateChangeClear(state) {
      state.bookingForDateChange.data = {};
      state.bookingForDateChange.error = "";
    },

    resetWasBookingInProgress(state) {
      state.wasBookingInProgress = false;
    },

    getUpcomingTripData() {},
    getUpcomingTripDataFulfilled() {},
    getUpcomingTripDataFailed() {},
    getUpcomingTripDataCancel() {},

    // anlytics only events
    submitConfirmationToolEvt() {},
    viewRequestInvoiceFormEvt() {},
  },
});

export const {
  addPackage,
  addPackageCancel,
  addPackageFailed,
  addPackageFulfilled,
  addReservation,
  addReservationCancel,
  addReservationClear,
  addReservationFailed,
  addReservationFulfilled,
  alipayPaymentFailed,
  alipayPaymentFailedClear,
  alipayPaymentInitialisedClear,
  alipayPaymentInitialisedSet,
  bookingInProgressPhaseClear,
  bookingInProgressPhaseSet,
  cancelBooking,
  cancelBookingCancel,
  cancelBookingFailed,
  cancelBookingFulfilled,
  changeRoomEvt,
  clearBookingErrors,
  clearBookingInProgress,
  clearDeepLinkErrors,
  completeBooking,
  completeBookingFailed,
  completeBookingFulfilled,
  completeBookingCancel,
  constructBooking,
  constructBookingCancel,
  constructBookingFailed,
  constructBookingFulfilled,
  constructBookingByBookingId,
  constructBookingByBookingIdCancel,
  constructBookingByBookingIdFailed,
  constructBookingByBookingIdFulfilled,
  constructBookingByResultSet,
  constructBookingByResultSetCancel,
  constructBookingByResultSetFailed,
  fetchBookingInProgress,
  fetchBookingInProgressCancel,
  fetchBookingInProgressFailed,
  fetchBookingInProgressFulfilled,
  fetchHistoricBooking,
  fetchHistoricBookingAndContent,
  fetchHistoricBookingCancel,
  fetchHistoricBookingFailed,
  fetchHistoricBookingForRegistration,
  fetchHistoricBookingForRegistrationCancel,
  fetchHistoricBookingForRegistrationFailed,
  fetchHistoricBookingForRegistrationFulfilled,
  fetchHistoricBookingFulfilled,
  getRatesForDateChange,
  getRatesForDateChangeCancel,
  getRatesForDateChangeClear,
  getRatesForDateChangeFailed,
  getRatesForDateChangeFulfilled,
  modifyHotelProductCust,
  modifyHotelProductCustClear,
  modifyHotelProductCustFailed,
  modifyHotelProductCustFulfilled,
  multiRoomUpsellEvt,
  removeContactCustomerPayment,
  removeContactCustomerPaymentFulfilled,
  removeContactCustomerPaymentFailed,
  removeContactCustomerPaymentCancel,
  requestInvoice,
  requestInvoiceClear,
  requestInvoiceFailed,
  requestInvoiceFulfilled,
  resetBookings,
  resetWasBookingInProgress,
  retrieveBooking,
  singleRoomUpsellEvt,
  submitBookingPreferences,
  submitBookingPreferencesCancel,
  submitBookingPreferencesFailed,
  submitBookingPreferencesFulfilled,
  submitConfirmationToolEvt,
  subscribeToAllCancel,
  subscribeToAllEmails,
  subscribeToAllFailed,
  subscribeToAllFulfilled,
  upgradeRooms,
  upgradeRoomsCancel,
  upgradeRoomsFailed,
  upgradeRoomsFulfilled,
  upgradeRoomsClear,
  viewPackageIncludedEvt,
  viewPackagesEvt,
  viewRequestInvoiceFormEvt,
  viewNextRoomEvt,
  getUpcomingTripData,
  getUpcomingTripDataFulfilled,
  getUpcomingTripDataFailed,
  getUpcomingTripDataCancel,
  setBookingInProgressId,
} = bookingsSlice.actions;

export default bookingsSlice.reducer;
