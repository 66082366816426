import get from "lodash/get";
import head from "lodash/fp/head";

import { EMPLOYEE_SEARCH_ERRORS } from "store/searchResults/checkEmployeeSearchParams";
import { NEXT_BOOKING_PHASES, hiddenSubscriptions } from "fixtures/constants";
import { BOOKING_STATUSES } from "./constants";

export const selectBookingsApiErrors = (state) =>
  state.bookings?.apiErrors || [];

export const selectBookingsApiError = (state) =>
  (selectBookingsApiErrors(state) || [])[0];

export const selectBookingSupplierErrors = (state) =>
  state.bookings?.supplierErrors || [];

export const selectBookingSupplierError = (state) =>
  (selectBookingSupplierErrors(state) || [])[0];

export const selectBookingError = (state) =>
  head([
    ...selectBookingsApiErrors(state),
    ...selectBookingSupplierErrors(state),
    ...(state.bookings?.errors ? state.bookings?.errors : []),
  ]) || "";

export const selectDeepLinkApiErrors = (state) =>
  (state.bookings?.deepLinkErrors?.apiErrors || []).map(({ errorCode }) => ({
    errorCode,
  }));

export const selectDeepLinkSupplierErrors = (state) =>
  (state.bookings?.deepLinkErrors?.supplierErrors || []).map(
    ({ errorCode }) => ({
      errorCode: `error.${errorCode}`,
    })
  );

export const selectDeepLinkError = (state) =>
  head([
    ...selectDeepLinkSupplierErrors(state),
    ...selectDeepLinkApiErrors(state),
  ])?.errorCode;

export const isEmployeeBookingError = (error) =>
  Object.values(EMPLOYEE_SEARCH_ERRORS).includes(error.errorCode);

export const selectEmployeeBookingErrors = (state) =>
  state.bookings?.errors?.filter(isEmployeeBookingError);

export const selectEmployeeBookingError = (state) =>
  (selectEmployeeBookingErrors(state) || [])[0];

export const selectUpgradedRooms = (state) => state.bookings?.upgradedRooms;

export const selectBooking =
  (bookingId) =>
  ({ bookings }) => {
    return bookings.bookingsInProgress[bookingId];
  };

export const selectBookingByReservationId =
  ({ reservationId }) =>
  ({ bookings }) => {
    return bookings.byReservationId[reservationId] || {};
  };

export const selectBookingByBookingId =
  ({ bookingId }) =>
  (state) => {
    const { byReservationId } = state.bookings;
    return Object.values(byReservationId).find(
      (b) => b.bookingId === bookingId
    );
  };

// selectBooking will add cancellation ref number if present
export const selectBookingWithCancellationRef = (bookingId) => (state) => {
  const booking = selectBookingByBookingId({ bookingId })(state);
  /* eslint-disable-next-line react/destructuring-assignment */
  const cancelRefs = state.bookings.cancelRefs[bookingId] || {};
  if (!booking) {
    return null;
  }

  return {
    ...booking,
    /* eslint-disable-next-line react/destructuring-assignment */
    bookingStatus: state.bookings.cancelRefs[bookingId]
      ? BOOKING_STATUSES.CANCELLED
      : booking.bookingStatus,
    hotelProducts: booking.hotelProducts.map((hotelProduct) => {
      const cancelRef = cancelRefs[hotelProduct.productId]?.reference;
      return {
        ...hotelProduct,
        cancelRef,
        bookingStatus: cancelRef
          ? BOOKING_STATUSES.CANCELLED
          : hotelProduct.bookingStatus,
      };
    }),
  };
};

export const selectBookingInProgressId = ({ bookings }) =>
  bookings.bookingInProgressId;

export const selectBookingInProgressSearchParams = ({ bookings }) =>
  bookings.bookingInProgressSearchParams;

export const selectBookingInProgress = (state) => {
  const { bookingInProgressId } = state.bookings;
  return bookingInProgressId && selectBooking(bookingInProgressId)(state);
};
export const selectBookingInProgressHotelCode = (state) => {
  return selectBookingInProgressSearchParams(state)?.hotelCode;
};

const getInitialDataForMakingReservation = (
  { locale, redirectLocation, ...formValues },
  {
    bookings: {
      bookingInProgressId: bookingId,
      bookingsInProgress,
      bookingInProgressSearchParams: searchParams = {},
    },
  }
) => {
  const bookingInProgress = bookingsInProgress[bookingId];
  const {
    hotelProducts,
    price: {
      total: {
        cash: { amount },
      },
    },
  } = bookingInProgress;

  return {
    amount,
    bookingId,
    hotelProducts,
    formValues,
    locale,
    searchParams,
    redirectLocation,
  };
};

export const selectBookingInProgressStatePhase = (state) => {
  return state.bookings?.bookingInProgressPhase;
};

export const selectBookingInProgressPhase = (payload) => (state) => {
  const { locale, ...formValues } = payload;
  const salesChannel = state.router?.location?.query?.channel;
  const globalSettings = state.globalSettings?.data;
  const mailingLists =
    formValues?.isUserLoggedIn && formValues?.termsAndConditions?.optInAgreement
      ? (state.globalMailSubscriptions?.data || []).reduce(
          (acc, subscription) => {
            if (
              subscription.mailingList &&
              !hiddenSubscriptions.includes(subscription.mailingList)
            ) {
              acc.push(subscription.mailingList);
            }
            return acc;
          },
          []
        )
      : undefined;

  const bookingInProgressPhase = state.bookings?.bookingInProgressPhase || {};
  return {
    phase: bookingInProgressPhase.phase || NEXT_BOOKING_PHASES.ADD_CONTACT,
    data: {
      ...(bookingInProgressPhase.phase
        ? {
            ...bookingInProgressPhase.data,
            locale,
            formValues: {
              ...formValues,
              ...(formValues?.paymentMethod === "alipay" &&
              bookingInProgressPhase.data?.formValues?.paymentMethod ===
                "alipay" &&
              bookingInProgressPhase.data.formValues.creditCard?.number
                ? {
                    creditCard:
                      bookingInProgressPhase.data.formValues.creditCard,
                  }
                : {}),
            },
            searchParams: state.bookings.bookingInProgressSearchParams || {},
          }
        : getInitialDataForMakingReservation(payload, state)),
      salesChannel,
      globalSettings,
      mailingLists,
    },
  };
};

export const selectBookingInProgressFormValues = ({ bookings }) => {
  return bookings?.bookingInProgressPhase?.data?.formValues;
};

export const selectHasBookingInProgress = (state) =>
  Boolean(selectBookingInProgress(state));

export const selectCompletedBooking = (state) => {
  const { bookingInProgressId } = state.bookings;
  return selectBookingByBookingId({ bookingId: bookingInProgressId })(state);
};

export const selectHasCompletedBooking = (state) => {
  return Boolean(selectCompletedBooking(state));
};

export const selectCheckoutModifyToken = (state) => {
  return state.bookings.checkoutModifyToken;
};

export const selectCancelRefs =
  (bookingId) =>
  ({ bookings }) => {
    return Object.values(bookings.cancelRefs[bookingId] || {});
  };

export const selectIsBookingCancelled = (bookingId) => (state) => {
  return (
    selectBookingWithCancellationRef(bookingId)(state)?.bookingStatus ===
    BOOKING_STATUSES.CANCELLED
  );
};

export const selectBookingHistoryErrors = (bookingId) => (state) => {
  const booking = state.bookings?.bookingsInProgress[bookingId] || {};

  return [...(booking.supplierErrors || []), ...(booking.apiErrors || [])];
};

export const selectRequestInvoiceError = ({ bookings }) =>
  bookings.invoiceRequestError;

export const selectAddReservationError = ({ bookings }) =>
  bookings.addReservationError;

export const selectAlipayError = ({ bookings }) => bookings.alipayError;

export const selectAlipayPaymentInitialised = ({ bookings }) =>
  bookings.alipayPaymentInitialised;

export const selectModifyDatesError = ({ bookings }) =>
  bookings.modifyDatesError;

export const selectBookingPhoneNumber =
  ({ reservationId }) =>
  (state) => {
    const booking = selectBookingByReservationId({ reservationId })(state);
    return get(booking, ["contactDetails", "phones", 0, "number"]);
  };

export const selectBookingForDateChange = ({ bookings }) =>
  bookings?.bookingForDateChange?.data;

export const selectBookingForDateChangeError = ({ bookings }) =>
  bookings?.bookingForDateChange?.error?.apiErrorCode ||
  bookings?.bookingForDateChange?.error?.supplierErrorCode ||
  "";

export const selectWasBookingInProgress = ({ bookings }) =>
  bookings?.wasBookingInProgress;
