import React from "react";

import "../../stylesheets/sass/profile_2018.scss";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { parse, format } from "date-fns";
import { setView } from "store/app";
import { getIsoCodeForLocale } from "config/languages";
import { useTranslation } from "hooks";
import { BookingFlowLoadingIndicator } from "BookingFlow/components";
import { getHMTLTagClassNames } from "utils";
import ProfileLayout from "../ProfileLayout";

import useEmployeeStayHistory from "./hooks/useEmployeeStayHistory";
import YearDisplay from "./components/YearDisplay";

function EmployeeStayHistoryView() {
  const {
    employeeName,
    employeeStayHistory,
    properties,
    showLoadingIndicator,
    displayYears,
    yearlyEntitlement,
    serviceDate,
    employeeType,
    position,
    furloughed,
    employeeRoomNights,
  } = useEmployeeStayHistory();
  const { i18n, locale, t } = useTranslation();
  const dispatch = useDispatch();
  dispatch(setView("EmployeeStayHistoryView"));
  const svcDate = parse(serviceDate, "yyyy-MM-dd", new Date());
  const formattedServiceDate = format(svcDate, "d-MMM-yyyy");
  const employeePosition = furloughed ? t("Furloughed") : t(position);

  return (
    <>
      <Helmet>
        <html
          lang={getIsoCodeForLocale(i18n.language)}
          className={getHMTLTagClassNames({
            locale,
            dir: i18n.dir(i18n.language),
            kind: "fs-profile-2018",
          })}
        />
      </Helmet>
      <ProfileLayout>
        {showLoadingIndicator && <BookingFlowLoadingIndicator />}
        <div className="employee-stay-history">
          <div className="container">
            <div className="employee-stats">
              <h1 className="ty-h3">{t("Employee Stay History")}</h1>
              <h1 className="user-name">
                {`${employeeName.firstName} ${employeeName.surname}`.trim()}
              </h1>
              <h1 className="status">
                {t("{0} {1} Employee", [employeePosition, employeeType])}
              </h1>
              <h1 className="continuous-service-date">
                {t("Continuous service date: {0}", [formattedServiceDate])}
              </h1>
              <div className="employee-booking-stats">
                <div className="stat-column">
                  <h1 className="stat-value">
                    {t("{0} nights", [yearlyEntitlement])}
                  </h1>
                  <h1 className="stat-label">{t("Yearly Entitlement")}</h1>
                </div>
                <div className="stat-column">
                  <h1 className="stat-value">
                    {employeeRoomNights.consumedLastServiceYear}
                  </h1>
                  <h1 className="stat-label">{t("Consumed Nights")}</h1>
                </div>
                <div className="stat-column">
                  <h1 className="stat-value">
                    {employeeRoomNights.futureLastServiceYear}
                  </h1>
                  <h1 className="stat-label">{t("Future Bookings")}</h1>
                </div>
                <div className="stat-column">
                  <h1 className="stat-value">
                    {employeeRoomNights.nightsRemainingLastServiceYear}
                  </h1>
                  <h1 className="stat-label">{t("Nights Remaining")}</h1>
                </div>
              </div>
            </div>
            <div className="stay-history">
              {displayYears.map((displayYear) => (
                <div className="year-history" key={displayYear}>
                  <YearDisplay
                    displayYear={displayYear}
                    properties={properties}
                    stays={
                      employeeStayHistory.find(
                        ({ year }) => year === displayYear
                      )?.stays
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </ProfileLayout>
    </>
  );
}

export default EmployeeStayHistoryView;
