import { ofType } from "redux-observable";
import { of } from "rxjs";
import { switchMap, takeUntil, map, catchError } from "rxjs/operators";

import { getGuestRequests$ } from "api/messenger";
import {
  fetchGuestRequests,
  fetchGuestRequestsFulfilled,
  fetchGuestRequestsFailed,
  fetchGuestRequestsCancel,
} from "../guestRequests.slice";

const fetchGuestRequestsEpic = (action$) =>
  action$.pipe(
    ofType(fetchGuestRequests.type),
    switchMap(({ payload: { bookingId, reservationId } }) => {
      return getGuestRequests$({ bookingId }).pipe(
        map((guestRequests) =>
          fetchGuestRequestsFulfilled({
            reservationId,
            guestRequests,
          })
        ),

        catchError((error) => {
          return of(fetchGuestRequestsFailed({ reservationId, error }));
        }),

        takeUntil(action$.pipe(ofType(fetchGuestRequestsCancel.type)))
      );
    })
  );

export default fetchGuestRequestsEpic;
