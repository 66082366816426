import React, { useContext } from "react";
import getOr from "lodash/fp/getOr";

import env from "config/env";
import { MediaContext } from "contexts/MediaContext";
import { isAndroid, isIOS, isMobileApp } from "utils";
import { useTranslation } from "hooks";
import {
  BookingFlowLoadingIndicator,
  DictionaryKeyContent,
} from "BookingFlow/components";
import usePreferencesForm from "../hooks/usePreferencesForm";
import Aside from "./Aside";
import ConfirmationContact from "./ConfirmationContact";
import MainSection from "./MainSection";
import MobileCheckInCheckOutDates from "./MobileCheckInCheckOutDates";
import PreferencesSavedModal from "./PreferencesSavedModal";
import SignInOrRegisterBlock from "./SignInOrRegisterBlock";
import YourBooking from "./YourBooking";

const { STATIC_SITE_URL, PREFERRED_PARTNER_DOMAIN, MOBILE_APP_URL_SCHEME } =
  env;

export default function PreferencesForm({
  checkoutModifyToken,
  completedBooking,
  bookingType,
  postBookingComments,
  initialArrivalTime,
  phoneNumber,
  hasBookingError,
  openCancellationModal,
  dictionaryKeys,
  rooms,
  showAppDownload,
  tollFreeNumbers,
  contactUs,
  property,
  propertyStatusLink,
  propertyTravelAdvisoryLink,
  isPreferredPartners,
  submitConfirmationTool,
  isUserLoggedIn,
  email,
  goToRegisterWithReservation,
  confirmationSummary,
  contactName,
  cancellation,
  isBookingCancelled,
  downloadMobileAppUrl,
  reservationId,
  sendGuestEmail,
  showBookingSurvey,
  setShowBookingSurvey,
  createSurveyLink,
}) {
  const { t } = useTranslation();
  const media = useContext(MediaContext);

  const {
    register,
    control,
    errors,
    formState,
    isSavingComments,
    showSuccessModal,
    closeSuccessModal,
    onSubmit,
  } = usePreferencesForm({
    bookingId: completedBooking.bookingId,
    bookingType,
    checkoutModifyToken,
    postBookingComments,
    initialArrivalTime,
    phoneNumber,
    hasBookingError,
    isPreferredPartners,
    reservationId,
    hotelCode: getOr("", ["hotelProducts", "0", "hotelCode"], completedBooking),
    sendGuestEmail,
  });
  const { checkInDate, checkOutDate } = completedBooking.hotelProducts[0];

  const returnLinkUrl = isMobileApp()
    ? MOBILE_APP_URL_SCHEME.returnHome
    : isPreferredPartners
    ? PREFERRED_PARTNER_DOMAIN
    : STATIC_SITE_URL;

  return (
    <form onSubmit={onSubmit} noValidate>
      <div className="confirmation">
        <div className="divide-block thank-you-message">
          <h1>
            <DictionaryKeyContent dict={dictionaryKeys.thankYouMessage} />
          </h1>
        </div>
        <div className="divide-block email-sent-message">
          {isPreferredPartners ? (
            <p className="confirmation-message">
              <DictionaryKeyContent dict={dictionaryKeys.emailSentMessage} />
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#" onClick={submitConfirmationTool}>
                <DictionaryKeyContent dict={dictionaryKeys.emailSentLinkText} />
              </a>
              <DictionaryKeyContent dict={dictionaryKeys.emailSentMessageEnd} />
            </p>
          ) : (
            <p className="confirmation-message">
              <DictionaryKeyContent dict={dictionaryKeys.emailSentMessage} />
            </p>
          )}
        </div>

        {(isAndroid() || isIOS()) && showAppDownload && !isMobileApp() && (
          <div className="visible-xs-block divide-block app-message">
            <a href={downloadMobileAppUrl}>
              {t("Download the Four Seasons App")}
            </a>
            {t(" to check in early and save time on arrival")}
          </div>
        )}

        <div className="confirmation-summary-baseline">
          {media.isGreaterThan.sm && (
            <div className="container">
              <div
                className="confirmation-summary"
                dangerouslySetInnerHTML={{
                  __html: confirmationSummary,
                }}
              />
            </div>
          )}

          {!media.isGreaterThan.sm && (
            <>
              {!isUserLoggedIn && !isPreferredPartners && (
                <SignInOrRegisterBlock
                  contactName={contactName}
                  email={email}
                  phoneNumber={phoneNumber}
                  goToRegisterWithReservation={goToRegisterWithReservation}
                />
              )}

              <MobileCheckInCheckOutDates
                checkIn={checkInDate}
                checkOut={checkOutDate}
              />

              <ConfirmationContact property={property} />

              <YourBooking
                completedBooking={completedBooking}
                dictionaryKeys={dictionaryKeys}
                cancellation={cancellation}
                property={property}
                register={register}
                control={control}
                errors={errors}
                rooms={rooms}
                isBookingCancelled={isBookingCancelled}
                openCancellationModal={openCancellationModal}
                isPreferredPartners={isPreferredPartners}
              />
            </>
          )}
        </div>
      </div>

      <div className="container confirmation-personalize">
        <div className="row">
          {media.isGreaterThan.sm && (
            <Aside
              isBookingCancelled={isBookingCancelled}
              cancellation={cancellation}
              dictionaryKeys={dictionaryKeys}
              isPreferredPartners={isPreferredPartners}
              property={property}
              tollFreeNumbers={tollFreeNumbers}
              contactUs={contactUs}
              showAppDownload={showAppDownload}
              openCancellationModal={openCancellationModal}
              submitConfirmationTool={submitConfirmationTool}
            />
          )}

          <MainSection
            completedBooking={completedBooking}
            contactName={contactName}
            dictionaryKeys={dictionaryKeys}
            email={email}
            goToRegisterWithReservation={goToRegisterWithReservation}
            isBookingCancelled={isBookingCancelled}
            isPreferredPartners={isPreferredPartners}
            phoneNumber={phoneNumber}
            postBookingComments={postBookingComments}
            rooms={rooms}
            register={register}
            control={control}
            errors={errors}
            formState={formState}
            showAppDownload={showAppDownload}
            downloadMobileAppUrl={downloadMobileAppUrl}
            propertyStatusLink={propertyStatusLink}
            propertyTravelAdvisoryLink={propertyTravelAdvisoryLink}
            showBookingSurvey={showBookingSurvey}
            setShowBookingSurvey={setShowBookingSurvey}
            createSurveyLink={createSurveyLink}
          />
        </div>
      </div>

      {isSavingComments && <BookingFlowLoadingIndicator />}

      {showSuccessModal && (
        <PreferencesSavedModal
          returnLink={returnLinkUrl}
          returnLinkLabel="Return to Homepage"
          closeModal={closeSuccessModal}
        />
      )}
    </form>
  );
}
