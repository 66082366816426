import { combineReducers } from "@reduxjs/toolkit";
import { combineEpics } from "redux-observable";
import { catchError } from "rxjs/operators";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { connectRouter } from "connected-react-router";
import { of } from "rxjs";

import { initialState as initialSignInState } from "store/signIn/signIn.slice";
import routerEpic from "store/router/router.epic";
import { appEpic, appReducer } from "./app";
import {
  accommodationsContentEpic,
  accommodationsContentReducer,
} from "./accommodationsContent";
import { addReservationModalReducer } from "./addReservationModal";
import { allPropertyContentEpic } from "./allPropertyContent";
import apiRequestStatesReducer from "./apiRequestStates/apiRequestStates.reducer";
import { appStatusEpic, appStatusReducer } from "./appStatus";
import { bookingCommentsEpic, bookingCommentsReducer } from "./bookingComments";
import { bookingFlowEpic } from "./bookingFlow";
import { bookingHistoryEpic, bookingHistoryReducer } from "./bookingHistory";
import { bookingMessagesEpic, bookingMessagesReducer } from "./bookingMessages";
import { bookingsEpic, bookingsReducer } from "./bookings";
import { initialState as initialBookingsState } from "./bookings/bookings.slice";
import { taxesContentEpic, taxesReducer } from "./taxesContent";
import {
  countriesContentEpic,
  countriesContentReducer,
} from "./countriesContent";
import { upsellsContentEpic, upsellsReducerContent } from "./upsellsContent";
import {
  termsAndConditionsContentEpic,
  termsAndConditionsContentReducer,
} from "./termsAndConditionsContent";
import {
  calendarAvailabilityEpic,
  calendarAvailabilityReducer,
} from "./calendarAvailability";
import {
  cartRecommendationsEpic,
  cartRecommendationsReducer,
} from "./cartRecommendations";
import {
  employeeCalendarAvailabilityEpic,
  employeeCalendarAvailabilityReducer,
} from "./employeeCalendarAvailability";
import { employeeProfileEpic, employeeProfileReducer } from "./employeeProfile";
import { exchangeRatesEpic, exchangeRatesReducer } from "./exchangeRates";
import {
  globalMailSubscriptionsEpic,
  globalMailSubscriptionsReducer,
} from "./globalMailSubscriptions";
import { globalSettingsEpic, globalSettingsReducer } from "./globalSettings";
import { guestRequestsEpic, guestRequestsReducer } from "./guestRequests";
import { memberDetailsEpic, memberDetailsReducer } from "./memberDetails";
import { offersContentEpic, offersContentReducer } from "./offersContent";
import { offersTermsAndConditionsContentReducer } from "./offersTermsAndConditionsContent";
import {
  preferredPartnerDetailsEpic,
  preferredPartnerDetailsReducer,
} from "./preferredPartnerDetails";
import { profileEpic, profileReducer } from "./profile";
import { propertiesEpic, propertiesReducer } from "./properties";
import { propertyContentEpic, propertyContentReducer } from "./propertyContent";
import {
  propertyWebCheckinEpic,
  propertyWebCheckinReducer,
} from "./propertyWebCheckin";
import { registrationReducer } from "./registration";
import { searchResultsEpic, searchResultsReducer } from "./searchResults";
import { signInReducer, signInEpic } from "./signIn";
import { stayHistoryReducer, stayHistoryEpic } from "./stayHistory";
import { paymentOptionsEpic, paymentOptionsReducer } from "./paymentOptions";
import { webCheckInEpic, webCheckInReducer } from "./webCheckIn";
import { workplacesEpic, workplacesReducer } from "./workplaces";
import { webChatReducer } from "./webChat";
import {
  transportationAmenitiesContentEpic,
  transportationAmenitiesContentReducer,
} from "./transportationAmenitiesContent";
import expireTransform from "./expireTransform";
import { toastNotificationReducer } from "./toastNotification";

const signInExpireIn = 5 * 60 * 1000; // expires in 5 minutes
const signInPersistConfig = {
  key: "signIn",
  storage,
  blacklist: ["requestOTP", "errors", "supplierErrors", "apiErrors"],
  transforms: [
    expireTransform(
      signInExpireIn,
      "signIn_persistencyExpiration",
      initialSignInState
    ),
  ],
};

const bookingsExpireIn = 45 * 60 * 1000; // expires in 45 minutes
const bookingsPersistConfig = {
  key: "bookings",
  storage,
  whitelist: [
    "alipayPaymentInitialised",
    "bookingInProgressId",
    "bookingInProgressSearchParams",
    "bookingInProgressPhase",
    "cancelRefs",
    "checkoutModifyToken",
  ],
  transforms: [
    expireTransform(
      bookingsExpireIn,
      "bookings_persistencyExpiration",
      initialBookingsState
    ),
  ],
};

const employeeProfilePersistConfig = {
  key: "employeeProfile",
  storage,
};

const upsellsPersistConfig = {
  key: "upsells",
  storage,
  blacklist: ["error"],
};

const searchResultsPersistConfig = {
  key: "searchResults",
  storage,
  blacklist: [
    "isLoading",
    "apiErrors",
    "supplierErrors",
    "missingLanguageError",
    "occupancyError",
  ],
};

const preferredPartnerDetailsPersistConfig = {
  key: "preferredPartnerDetails",
  storage,
};

const webChatPersistConfig = {
  key: "webChat",
  storage,
};

const toastNotificationPersistConfig = {
  key: "toastNotification",
  storage,
  whitelist: ["shouldOpen"],
};

const rootReducer = (history) =>
  combineReducers({
    apiRequestStates: apiRequestStatesReducer,
    app: appReducer,
    accommodationsContent: accommodationsContentReducer,
    addReservationModal: addReservationModalReducer,
    appStatus: appStatusReducer,
    bookingComments: bookingCommentsReducer,
    bookingHistory: bookingHistoryReducer,
    bookingMessages: bookingMessagesReducer,
    bookings: persistReducer(bookingsPersistConfig, bookingsReducer),
    calendarAvailability: calendarAvailabilityReducer,
    cartRecommendations: cartRecommendationsReducer,
    countries: countriesContentReducer,
    employeeCalendarAvailability: employeeCalendarAvailabilityReducer,
    employeeProfile: persistReducer(
      employeeProfilePersistConfig,
      employeeProfileReducer
    ),
    exchangeRates: exchangeRatesReducer,
    globalMailSubscriptions: globalMailSubscriptionsReducer,
    globalSettings: globalSettingsReducer,
    guestRequests: guestRequestsReducer,
    memberDetails: memberDetailsReducer,
    offersContent: offersContentReducer,
    offersTermsAndConditionsContent: offersTermsAndConditionsContentReducer,
    paymentOptions: paymentOptionsReducer,
    preferredPartnerDetails: persistReducer(
      preferredPartnerDetailsPersistConfig,
      preferredPartnerDetailsReducer
    ),
    profile: profileReducer,
    properties: propertiesReducer,
    propertyContent: propertyContentReducer,
    propertyWebCheckin: propertyWebCheckinReducer,
    registration: registrationReducer,
    router: connectRouter(history),
    searchResults: persistReducer(
      searchResultsPersistConfig,
      searchResultsReducer
    ),
    signIn: persistReducer(signInPersistConfig, signInReducer),
    stayHistory: stayHistoryReducer,
    taxes: taxesReducer,
    termsAndConditions: termsAndConditionsContentReducer,
    transportationAmenitiesContent: transportationAmenitiesContentReducer,
    upsells: persistReducer(upsellsPersistConfig, upsellsReducerContent),
    webCheckIn: webCheckInReducer,
    workplaces: workplacesReducer,
    webChat: persistReducer(webChatPersistConfig, webChatReducer),
    toastNotification: persistReducer(
      toastNotificationPersistConfig,
      toastNotificationReducer
    ),
  });

const epics = [
  accommodationsContentEpic,
  allPropertyContentEpic,
  appEpic,
  appStatusEpic,
  bookingCommentsEpic,
  bookingFlowEpic,
  bookingHistoryEpic,
  bookingMessagesEpic,
  bookingsEpic,
  calendarAvailabilityEpic,
  cartRecommendationsEpic,
  employeeCalendarAvailabilityEpic,
  countriesContentEpic,
  employeeProfileEpic,
  exchangeRatesEpic,
  globalMailSubscriptionsEpic,
  globalSettingsEpic,
  guestRequestsEpic,
  memberDetailsEpic,
  offersContentEpic,
  paymentOptionsEpic,
  preferredPartnerDetailsEpic,
  profileEpic,
  propertiesEpic,
  propertyContentEpic,
  propertyWebCheckinEpic,
  routerEpic,
  searchResultsEpic,
  signInEpic,
  stayHistoryEpic,
  taxesContentEpic,
  termsAndConditionsContentEpic,
  upsellsContentEpic,
  webCheckInEpic,
  workplacesEpic,
  transportationAmenitiesContentEpic,
];

// Adding global error handler
// https://redux-observable.js.org/docs/basics/SettingUpTheMiddleware.html#adding-global-error-handler
export const rootEpic = (action$, store$, dependencies) =>
  combineEpics(...epics)(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      if (error.actions) {
        return error.actions;
      }

      if (error.action) {
        return of(error.action);
      }

      console.error("ROOT EPIC:", error);
      return source;
    })
  );

export default rootReducer;
