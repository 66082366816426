import get from "lodash/get";
import { add, sub, isBefore, startOfDay, parse } from "date-fns";

import { getEmployeeServiceDateThisYear } from "utils/datesHelpers";
import { getEmployeeRateByCode } from "Profile/utils/getEmployeeRate";

export default function getEmployeeRoomNights({
  stayHistory = [],
  employeeProfile = {},
} = {}) {
  const today = startOfDay(new Date());
  let serviceDateThisYear;
  let serviceDateLastYear;
  serviceDateThisYear = getEmployeeServiceDateThisYear(employeeProfile);
  if (isBefore(today, serviceDateThisYear)) {
    serviceDateLastYear = sub(serviceDateThisYear, { years: 1 });
  } else {
    serviceDateLastYear = serviceDateThisYear;
    serviceDateThisYear = add(serviceDateThisYear, { years: 1 });
  }

  let consumedThisServiceYear = 0;
  let futureThisServiceYear = 0;
  let consumedLastServiceYear = 0;
  let futureLastServiceYear = 0;

  if (Array.isArray(stayHistory)) {
    stayHistory.forEach((booking) => {
      const bookingRateCode = get(
        booking,
        ["hotelProducts", 0, "rooms", "room", 0, "ratePlanCode"],
        ""
      );
      const stayEmployeeRate = getEmployeeRateByCode(bookingRateCode);
      if (
        !!booking.startDate &&
        !!booking.endDate &&
        !get(booking, ["hotelProducts", 0, "fsCachedExistingReservation"]) &&
        !!stayEmployeeRate
      ) {
        let stayDate = parse(booking.startDate, "yyyy-MM-dd", new Date());
        const stayEndDate = parse(booking.endDate, "yyyy-MM-dd", new Date());

        if (stayEmployeeRate.compRate) {
          while (isBefore(stayDate, stayEndDate)) {
            if (isBefore(stayDate, serviceDateThisYear)) {
              if (!isBefore(stayDate, serviceDateLastYear)) {
                if (isBefore(stayDate, today)) {
                  consumedLastServiceYear += 1;
                } else {
                  futureLastServiceYear += 1;
                }
              }
            } else if (isBefore(stayDate, today)) {
              consumedThisServiceYear += 1;
            } else {
              futureThisServiceYear += 1;
            }

            stayDate = add(stayDate, { days: 1 });
          }
        }
      }
    });
  }

  return {
    consumedThisServiceYear,
    futureThisServiceYear,
    nightsRemainingThisServiceYear:
      employeeProfile.yearlyEntitlement -
      (consumedThisServiceYear + futureThisServiceYear),
    consumedLastServiceYear,
    futureLastServiceYear,
    nightsRemainingLastServiceYear:
      employeeProfile.yearlyEntitlement -
      (consumedLastServiceYear + futureLastServiceYear),
  };
}
